import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import Page from '../_library/Page'
import BackButton from '../_library/BackButton'
import { useMatch } from 'react-router'
import { EDIT_TEAM } from '../../_constants/routes'
import EditTeamForm from './EditTeamForm'
import cartographieImg from '../../assets/CARDS/card_cartographie.png'
import oneoneImg from '../../assets/CARDS/campfire_duo.png'
import { getTeamProfileType } from '../../_helpers/cardHelper'
import { ONE_TO_ONE_BUILDER, TEAM_PROFILE } from '../../_constants/cardTypes'
import useFirestore from '../../hooks/useFirestore'
import { CARD_TEMPLATE_COLLECTION, CONVERSATION_TYPES, TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import ppt1Img from '../../assets/CARDS/safeCocoon.png'
import ppt2Img from '../../assets/CARDS/teamBuilding.png'
import html2canvas from 'html2canvas'
import PersonalityGraph from '../_library/PersonalityGraph'
import { getSafeCocoonPPTX, getTeamBuildingPPTX } from '../../_helpers/pptxHelper'

const cardLinkStyle = {
  my: 2,
  display: 'flex',
  p: 0,
  width: '100%',
  '& label': {
    display: 'inline-block',
    verticalAlign: 'middle',
    bgcolor: 'tertiary.main',
    color: 'white',
    py: .5,
    px: 1,
    borderRadius: '5px',
    flex: 'auto',
    mr: '8%',
    cursor: 'inherit',
    textAlign: 'center',
  },
  '& img': {
    height: '50px',
    verticalAlign: 'middle',
  },
}

const EditTeamPage = () => {

  const match = useMatch(EDIT_TEAM)
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const teams = teamHooks.getDocs()
  const userHook = useFirestore(USER_COLLECTION)
  const users = userHook.getDocs()
  const cardTemplateHooks = useFirestore(CARD_TEMPLATE_COLLECTION)
  const dbCardTemplates = cardTemplateHooks.getDocs()
  
  const [loadingPPT, setLoadingPPT] = useState(false)
  
  const team = useMemo(() => match && teams?.find(c => c.id === match.params.id), [match, teams])
  const teamMembers = useMemo(() => users?.length ? team?.memberRefs?.map(ref => users.find(u => u.id === ref.id)) : [], [team, users])
  const sumUpCard = useMemo(() => dbCardTemplates?.find(t => t.type === 'sum-up'), [dbCardTemplates])
  const teamProfileCard = useMemo(() => dbCardTemplates?.find(t => t.type === 'team-profile'), [dbCardTemplates])
  
  useEffect(() => {cardTemplateHooks.listen({ orderBy: [['order', 'asc']] })}, [])
  
  const getCardUrl = type => `${process.env.REACT_APP_BASE_URL}/card/${type}?teamId=${team.id}&consultantMode=true`
  
  const downloadFile = async (type = 'teamBuilding') => {
    setLoadingPPT(true)
    const users = teamMembers.filter(m => m.profileType)
    const graphImage = await new Promise(resolve => {
      setTimeout(async () => {
        const canvas = await html2canvas(window.document.querySelector('#personalityGraph'), { useCORS: true })
        resolve(canvas.toDataURL())
      }, 1000)
    })
    const graphImageBg = await new Promise(resolve => {
      setTimeout(async () => {
        const canvas = await html2canvas(window.document.querySelector('#personalityGraphBg'), { useCORS: true })
        resolve(canvas.toDataURL())
      }, 1000)
    })
    if (type === CONVERSATION_TYPES.TEAM_BUILDING)
      getTeamBuildingPPTX('admin', team.company?.name, team.name, users, sumUpCard, team.id, graphImage, graphImageBg, teamProfileCard)
        .catch(err => { console.error(err) })
        .finally(() => { setLoadingPPT(false) })
    else getSafeCocoonPPTX('admin', team.company?.name, team, users, sumUpCard)
      .catch(err => { console.error(err) })
      .finally(() => { setLoadingPPT(false) })
  }

  return <Page title={team?.name || ''}>
    <Grid container sx={{
      backgroundColor: 'white',
      position: 'relative',
      minHeight: '300px',
      borderRadius: '22px',
      padding: 5,
      boxShadow: theme => `0 0 10px ${theme.palette.grey[200]}`,
    }}>
      <BackButton sx={{
        position: 'absolute',
        right: 40,
        top: -20,
      }} />
      <Grid item md={8} sx={{ borderRight: theme => `solid 2px ${theme.palette.grey[150]}` }}>
        {team
          ? <EditTeamForm team={team} />
          : <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
        }
      </Grid>
      <Grid item md={4}>
        <Box sx={{ width: '90%', maxWidth: 400, mx: 'auto' }}>
          {team?.id && <>
            <Button href={getCardUrl(TEAM_PROFILE)} target='_blank' sx={cardLinkStyle}>
              <label>Voir la cartographie &gt;</label>
              <img src={cartographieImg} alt='card talent' />
            </Button>
            <Button href={getCardUrl(ONE_TO_ONE_BUILDER)} target='_blank' sx={cardLinkStyle}>
              <label>Feu de camp duo &gt;</label>
              <img src={oneoneImg} alt='card management' />
            </Button>
            <Button disabled={loadingPPT} onClick={() => downloadFile('safeCocoon')} sx={cardLinkStyle}>
              <label>Feu de camp équipe • 1 &gt;</label>
              <img src={ppt1Img} alt='card position' />
            </Button>
            <Button disabled={loadingPPT} onClick={() => downloadFile('teamBuilding')} sx={cardLinkStyle}>
              <label>Feu de camp équipe • 2 &gt;</label>
              <img src={ppt2Img} alt='card communication' />
            </Button>
            <Typography color='primary.dark' fontWeight={600} mt={3} mb={2}>Profil d&apos;équipe : {teamMembers && getTeamProfileType(teamMembers.filter(u => u?.testResults))}</Typography>
            <Typography color='primary.dark' fontWeight={600}>Membres :</Typography>
            {teamMembers?.map(user => <Typography key={user.id} color='grey.500'>
              {`${user.profileType || '???'} - ${user.firstname}` || user.id}
            </Typography>)}
          </>}
          {loadingPPT && <PersonalityGraph
            id='personalityGraph'
            users={teamMembers.filter(m => m.profileType)}
            displayAvatars
            displayHeart
            style={{ position: 'fixed' }}
          />}
          {loadingPPT && <PersonalityGraph
            id='personalityGraphBg'
            users={teamMembers.filter(m => m.profileType)}
            displayAvatars
            displayHeart
            style={{ position: 'fixed', backgroundColor: '#F9EDCF' }}
          />}
        </Box>
      </Grid>
    </Grid>
  </Page>
}

export default EditTeamPage
